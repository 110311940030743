import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import { Alert, Link, Snackbar, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { red } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import Favicon from 'react-favicon';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { useSetRecoilState } from 'recoil';

import { SWUpdater } from '../Frontend/features/ServiceWorkerUpdater.js';
import { documentTitle } from '../Frontend/helpers/documentTitle.js';
import { isBeta } from '../Frontend/helpers/isBeta.js';
import { AccountMenu } from '../Frontend/Layout/features/AccountMenu.js';
import { AppBar } from '../Frontend/Layout/features/AppBarProperties.js';
import { Copyright } from '../Frontend/Layout/features/Copyright.js';
import { StyledDrawer } from '../Frontend/Layout/features/StyledDrawer.js';
import { Tour } from '../Frontend/Layout/Tour.js';
import { CustomerSelector } from '../Frontend/Navigation/CustomerSelector.js';
import { Menu } from '../Frontend/Navigation/Menu.js';
import { ScopeNavigation } from '../Frontend/ScopeNavigation/ScopeNavigation.js';
import { useBrandingQuery } from '../hooks/query/useBrandingQuery.js';
import { useError } from '../KaeplaErrorProvider.js';
import { ProtectedRoute } from '../primitive/ProtectedRoute.js';
import { applicationState } from '../services/recoil/nonpersistent/applicationState.js';
import { pageState } from '../services/recoil/persistent/pageState.js';

interface Options {
  hasScopeNavigation?: boolean;
  showCustomerSelector?: boolean;
}

export function AppLayout({ hasScopeNavigation, showCustomerSelector }: Options) {
  const navigate = useNavigate();
  const { data: branding } = useBrandingQuery();
  const theme = useTheme();
  const { lastError, snackOpen, handleSnackClose } = useError();

  const location = useLocation();

  const setApplication = useSetRecoilState(applicationState);
  const setPage = useSetRecoilState(pageState);
  const [open, setOpen] = useState(false);
  const [mainTour, setMainTour] = useState(true);
  const [guiTour, setGuiTour] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setPage(location.pathname);
    if (branding) return;
    document.title = documentTitle(branding);
  }, [branding, location, setPage]);

  return (
    <Box sx={{ display: 'flex' }}>
      {branding?.appIconBase64 && <Favicon url={branding?.appIconBase64} />}
      <AppBar data-testid="app-header-bar" position="absolute" open={open} color="inherit">
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            data-testid="drawer-toggle"
            id="burger-menu"
            onClick={toggleDrawer}
            sx={{
              marginRight: { xs: 1, sm: 3 },
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box>
            {branding?.appIconBase64 && (
              <Link
                sx={{ width: { xs: 25, sm: 32 }, height: { xs: 25, sm: 32 }, cursor: 'pointer' }}
                component="div"
                onClick={() => {
                  setApplication((oldApplication) => ({
                    ...oldApplication,
                    tourLastStarted: null,
                  }));
                  void navigate('/Disclaimers');
                }}
              >
                <img
                  data-testid="app-icon"
                  width="100%"
                  src={branding.appIconBase64}
                  alt={branding?.appName || 'Logo'}
                />
              </Link>
            )}
          </Box>
          <Tour
            mainTour={mainTour}
            setMainTour={setMainTour}
            guiTour={guiTour}
            setGuiTour={setGuiTour}
            disabled
          />
          <Typography
            data-testid="scopenavigation"
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {hasScopeNavigation && <ScopeNavigation />}
          </Typography>
          {showCustomerSelector && <CustomerSelector />}
          {isBeta() && <Box sx={{ color: red[500], fontSize: 14, ml: 2 }}>β</Box>}
          <AccountMenu />
          <SWUpdater />
        </Toolbar>
      </AppBar>
      <StyledDrawer id="left-menu" variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav" data-testid="left-menu">
          <Menu hasScope={hasScopeNavigation} />
        </List>
      </StyledDrawer>
      <Box
        component="main"
        sx={{
          backgroundColor:
            theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
          sx={{ height: '100%' }}
        >
          <Toolbar />
          <Snackbar
            open={snackOpen}
            onClose={handleSnackClose}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          >
            <Alert elevation={8} onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
              {lastError?.message}
            </Alert>
          </Snackbar>
          <Box id="page-content" data-testid="page-content" sx={{ p: 3 }}>
            <Outlet />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
            }}
          />
          <Copyright branding={branding} />
        </Stack>
      </Box>
    </Box>
  );
}

export default function Layout() {
  return (
    <ProtectedRoute>
      <AppLayout hasScopeNavigation={false} showCustomerSelector={false} />
    </ProtectedRoute>
  );
}
