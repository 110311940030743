/* eslint-disable @typescript-eslint/naming-convention */
// eslint-disable-next-line import/no-unassigned-import
import './logging';

import {
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  LinkProps,
  Stack,
  Typography,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { QueryClientProvider, QueryErrorResetBoundary } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useMemo, useState } from 'react';
import { ErrorBoundary as OfflineErrorBoundary } from 'react-error-boundary';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router';
import { RecoilRoot } from 'recoil';

import { AuthReactProvider, useAuth } from './AuthReactProvider.js';
import { queryClient } from './config/client.js';
import { ColorModeContext } from './Frontend/Layout/features/ColorModeToggle.js';
import { ConnectFailed } from './Frontend/Screens/Login/features/ConnectFailed.js';
import { MoreLoginOptions } from './Frontend/Screens/Login/features/MoreLoginOptions';
import { Login, LoginLayout } from './Frontend/Screens/Login/Login';
import { Logout } from './Frontend/Screens/Login/Logout.js';
import { themeOptions } from './Frontend/Theme/KaeplaTheme.js';
import { useBrandingQuery } from './hooks/query/useBrandingQuery.js';
import { IS_DEV } from './mode.js';
import { ErrorBoundary } from './primitive/ErrorBoundary';
import { LinkBehavior } from './primitive/LinkBehavior';
import { PageLoading } from './primitive/PageLoading';
import { RedirectToAppRoute } from './primitive/ProtectedRoute';
import { BrandingError } from './repository/Branding.repository.js';
import { RootLayout } from './Root.layout';
import { AppLayout } from './routes/app.layout';
import { projectLoader } from './routes/project/project.loader.js';
import { ProjectDetailPage, projectDetailPageLoader } from './routes/project/ProjectDetailPage.js';
import { ProjectErrorPage } from './routes/project/ProjectErrorPage.js';
import { ProjectRootLayout } from './routes/project/ProjectLayout.js';
import { ProjectListPage, projectListPageLoader } from './routes/project/ProjectListPage.js';
import { ProjectSetupPage, projectSetupPageLoader } from './routes/project/ProjectSetupPage.js';

const AppWithoutStrictMode = () => {
  const { authState } = useAuth();

  if (authState === null) {
    return (
      <Box
        data-testid={'router-loading'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        height={'100vh'}
        width={'full'}
      >
        <CircularProgress />
      </Box>
    );
  }

  //
  // some loader requires getAuth
  //
  // when router is defined outside of AppWithoutStrictMode the initialization of the repositories doesn't have access to auth
  //
  return (
    <RouterProvider
      router={createBrowserRouter(
        createRoutesFromElements(
          <Route
            HydrateFallback={PageLoading}
            errorElement={<ErrorBoundary scope="root" />}
            element={<RootLayout />}
          >
            <Route
              path="/Login"
              element={
                <RedirectToAppRoute>
                  <Login />
                </RedirectToAppRoute>
              }
            />
            <Route
              path="/LoginOptions"
              element={
                <RedirectToAppRoute>
                  <MoreLoginOptions />
                </RedirectToAppRoute>
              }
            />
            <Route path="/Logout" element={<Logout />} />
            {
              ///
              ///
              /// NEW ROUTES FOR REWRITE
              ///
              ///
            }
            <Route path="app" errorElement={<ErrorBoundary scope="/app" />} element={<AppLayout />}>
              <Route index path="" element={<Navigate to={'/app/project'} />} />
              <Route
                path="project"
                id={'project'}
                loader={projectLoader}
                errorElement={
                  <ErrorBoundary scope="/project">
                    <ProjectErrorPage />
                  </ErrorBoundary>
                }
                element={<ProjectRootLayout />}
              >
                <Route
                  id="project-customer"
                  errorElement={<ErrorBoundary scope="Project" />}
                  loader={projectListPageLoader}
                  path=":customerId"
                >
                  <Route id="project-list" index element={<ProjectListPage />} />
                  <Route
                    loader={projectSetupPageLoader}
                    id="project-setup"
                    path=":projectId/setup"
                    element={<ProjectSetupPage />}
                  />
                  <Route
                    loader={projectDetailPageLoader}
                    id="project-detail"
                    path=":projectId"
                    element={<ProjectDetailPage />}
                  />
                </Route>
              </Route>
              <Route path="*" element={<Navigate to={'/app/project'} />} />
            </Route>

            <Route
              path="*"
              lazy={async () => {
                const app = await import('./App.router');
                return {
                  Component: app.AppRouter,
                  errorElement: <ErrorBoundary scope="*" />,
                };
              }}
            />
          </Route>,
        ),
        {
          future: {},
        },
      )}
    />
  );
};

export function App() {
  const query = useBrandingQuery();
  return (
    <AuthReactProvider branding={query.data}>
      <AppWithoutStrictMode />
      {IS_DEV && <ReactQueryDevtools buttonPosition="bottom-left" />}
    </AuthReactProvider>
  );
}

export const Root = () => {
  const [mode, setMode] = useState<'light' | 'dark'>('light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((previousMode) => (previousMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = useMemo(
    () =>
      createTheme(
        {
          components: {
            MuiTab: {
              defaultProps: {
                component: LinkBehavior,
              },
            },
            MuiMenuItem: {
              defaultProps: {
                component: LinkBehavior,
              },
            },
            MuiLink: {
              defaultProps: {
                component: LinkBehavior,
              } as LinkProps,
            },
            MuiButtonBase: {
              defaultProps: {
                LinkComponent: LinkBehavior,
              },
            },
          },
          palette: {
            mode,
          },
        },
        themeOptions,
      ),
    [mode],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <ColorModeContext.Provider value={colorMode}>
        <RecoilRoot>
          <QueryClientProvider client={queryClient}>
            <QueryErrorResetBoundary>
              {({ reset }) => (
                <OfflineErrorBoundary
                  onReset={reset}
                  fallbackRender={({ resetErrorBoundary, error }) => {
                    //
                    if (error instanceof BrandingError) {
                      return (
                        <LoginLayout>
                          <ConnectFailed />
                        </LoginLayout>
                      );
                    }
                    return (
                      <LoginLayout>
                        <Stack direction={'column'} spacing={2}>
                          <Typography variant={'h6'}>There was an error!</Typography>
                          <Button onClick={() => resetErrorBoundary()}>Try again</Button>
                        </Stack>
                      </LoginLayout>
                    );
                  }}
                >
                  <App />
                </OfflineErrorBoundary>
              )}
            </QueryErrorResetBoundary>
          </QueryClientProvider>
        </RecoilRoot>
      </ColorModeContext.Provider>
    </ThemeProvider>
  );
};
