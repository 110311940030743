import { PropsWithChildren } from 'react';
import { Navigate, useParams } from 'react-router';

import { useAuth } from '../AuthReactProvider';

import { PageLoading } from './PageLoading';

export function ProtectedRoute({ children }: PropsWithChildren<unknown>) {
  const { authState } = useAuth();

  if (authState === null) {
    return <PageLoading />;
  }
  if (!authState) {
    return <Navigate to="/Login" />;
  }
  return children;
}

export function RedirectToAppRoute({ children }: PropsWithChildren<unknown>) {
  const { authState } = useAuth();

  if (authState === null) {
    return <PageLoading />;
  }
  if (authState) {
    return <Navigate to="/" />;
  }
  return children;
}

export function RedirectToProjectEvents() {
  const parameters = useParams();

  return <Navigate to={`/ProjectEvents/${parameters.projectId}`} />;
}
