export const cleanColumnName = (columnName: string) => {
  // return `${columnName}`.replaceAll('__', replacement).replaceAll('_', replacement);
  let cleanedLabel = columnName;

  // replace prefix 'Kaepla__'
  cleanedLabel = cleanedLabel.replace(/^Kaepla__/, '');

  // replace suffix '__Name'
  cleanedLabel = cleanedLabel.replace(/__Name$/, '');

  // remove prefix, ie Change_Item__Production_Plant__Production_Plant__Name -> Production_Plant__Production_Plant__Name
  const parts = cleanedLabel.split('__');
  if (parts.length > 1) {
    cleanedLabel = parts.slice(1).join('__');
  }

  // remove redundancies, ie Production_Plant__Production_Plant__Name -> Production_Plant__Name
  cleanedLabel = [...new Set(cleanedLabel.split('__'))].join('__');

  // replace underscores with spaces
  cleanedLabel = cleanedLabel.replaceAll(/_+/g, ' ').trim();

  return cleanedLabel;
};

// TODO: have a concept for this
export const getColumnAbbreviation = (dimension: string) => {
  return `${dimension}`
    .replaceAll('Corporate_', 'Corp_')
    .replaceAll('Volume', 'Vol')
    .replaceAll('Turnover', 'TO')
    .replaceAll('Reference_Object__', 'RO_')
    .replaceAll('Global_Nameplate', 'Nameplate')
    .replaceAll('Global_Sales_Segment', 'Sales_Segment')
    .replaceAll('Production_Brand', 'Brand')
    .replaceAll('Reference_Object__Monthly', 'Vehicle');
};
