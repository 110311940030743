import { KaeplaProject } from '@kaepla/types';
import { Alert } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { useGetServices } from '../../../../hooks/useGetServices.js';
import { renameProject } from '../../../../services/firestore/renameProject.js';

interface Options {
  project: KaeplaProject;
  open: boolean;
  handleClose: () => void;
  handleMenuClose: () => void;

  useService?: boolean;
}

export function ProjectRenameDialog({
  project,
  open,
  handleClose,
  handleMenuClose,
  useService,
}: Options) {
  const { projectService } = useGetServices();
  const mutation = useMutation(projectService.renameProject);
  const queryClient = useQueryClient();
  const [newName, setNewName] = useState(project.name);
  const [renaming, setRenaming] = useState(false);
  const [updatedAt] = useState(project.updatedAt);

  const renamingOnServer = () => {
    setRenaming(true);
    const newProject = { ...project };
    newProject.name = newName;
    if (useService) {
      void mutation.mutate(
        {
          id: project.id,
          name: newName,
        },
        {
          async onSuccess() {
            await queryClient.invalidateQueries(
              projectService.findManyProjectDTO({
                customerId: project.customerId,
              }),
            );
            setRenaming(false);
            handleClose();
            handleMenuClose();
          },
        },
      );
    } else {
      void renameProject({ projectId: project.id, name: newName });
    }
  };

  useEffect(() => {
    if (useService) return;
    if (!updatedAt) return;
    if (!project.updatedAt) return;
    if (updatedAt.seconds !== project.updatedAt.seconds) {
      setRenaming(false);
      handleClose();
      handleMenuClose();
    }
  }, [handleClose, handleMenuClose, project.updatedAt, updatedAt, useService]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle data-testid="dialog-title">Rename the project</DialogTitle>
      <DialogContent>
        <DialogContentText data-testid="rename-info">
          Renaming might take a few seconds. When the project is being worked on, renaming will be
          disabled.
        </DialogContentText>
        {renaming && (
          <Alert data-testid="rename-progress-message" variant="outlined" severity="info">
            Renaming Project in progress...
          </Alert>
        )}
        <TextField
          autoFocus
          margin="dense"
          fullWidth
          variant="standard"
          value={newName}
          slotProps={{
            htmlInput: { 'data-testid': 'project-name-input' },
          }}
          onChange={(event) => {
            setNewName(event.target.value);
            event.preventDefault();
          }}
          onKeyUp={(event) => {
            if (!newName) return;
            if (event.key === 'Enter') {
              void renamingOnServer();
            }
          }}
          disabled={renaming}
        />
      </DialogContent>
      <DialogActions>
        <Button data-testid="button-cancel-rename" onClick={handleClose} disabled={renaming}>
          Cancel
        </Button>
        <Button
          disabled={renaming}
          data-testid="button-rename"
          variant="contained"
          color="error"
          onClick={() => {
            if (!newName) return;
            void renamingOnServer();
          }}
        >
          Rename
        </Button>
      </DialogActions>
    </Dialog>
  );
}
