import { Box, Grid2, Paper, Stack, Typography } from '@mui/material';
import { isRouteErrorResponse, useRouteError } from 'react-router';
import { isValidationError } from 'zod-validation-error';

import {
  FirebasePermissionError,
  isNotFoundError,
} from '../repository/AbstractClasses/FirebaseRepository';

interface ErrorBoundaryProperties {
  scope?: string;
  children?: React.ReactNode;
}

export function ErrorLayout({ scope }: ErrorBoundaryProperties) {
  const error = useRouteError() as Error;
  return (
    <Box display={'flex'} flexDirection={'column'} p={2}>
      <Stack spacing={2}>
        {scope && (
          <Paper>
            <Typography p={2} variant="h1" color="textPrimary">
              Scope: {scope}
            </Typography>
          </Paper>
        )}
        <Paper>
          <Stack spacing={2} p={2}>
            <Typography variant="h2" color="textPrimary">
              {error.name}
            </Typography>
            <Typography variant="h3" color="textPrimary">
              {error.message}
            </Typography>
            <Typography variant="body2" color="textPrimary" component={'pre'}>
              {error.stack}
            </Typography>
          </Stack>
        </Paper>
      </Stack>
    </Box>
  );
}

export function ErrorBoundary({ scope, children }: ErrorBoundaryProperties) {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    return (
      <Box display={'flex'} flexDirection={'column'} p={2}>
        <Paper>
          <Stack spacing={2} p={2}>
            <Typography variant="h2" color="textPrimary">
              {error.status} {error.statusText}
            </Typography>

            <Typography variant="inherit" color="textPrimary">
              {error.data}
            </Typography>
          </Stack>
        </Paper>
      </Box>
    );
  } else if (isNotFoundError(error)) {
    return (
      <Paper>
        <Grid2 container spacing={2} p={2}>
          <Stack direction={'row'} spacing={1} p={2}>
            <Typography variant="h3" textTransform={'capitalize'} color="textPrimary">
              {error.entity.replace('/', '')}
            </Typography>
            <Typography variant="h3" color="textPrimary">
              not found
            </Typography>
          </Stack>
        </Grid2>
      </Paper>
    );
  } else if (error instanceof FirebasePermissionError) {
    return (
      <Box display={'flex'} flexDirection={'column'} p={2}>
        <Paper>
          <Stack spacing={2} p={2}>
            <Typography variant="h2" component={'span'} color="textPrimary">
              Permission Error
            </Typography>
            <Typography
              fontWeight={'bold'}
              variant="inherit"
              component={'span'}
              color="textPrimary"
            >
              Path
            </Typography>
            <Typography pl={2} component={'span'} fontWeight={'bold'} color={'error'}>
              {error.path}
            </Typography>
            <Typography
              fontWeight={'bold'}
              variant="inherit"
              component={'span'}
              color="textPrimary"
            >
              Cause
            </Typography>
            <Typography pl={2} component={'span'} fontWeight={'bold'} color={'error'}>
              {error.message}
            </Typography>
            <Typography variant="inherit" component={'span'} fontWeight={'bold'}>
              Parameter
            </Typography>
            <Typography pl={2} component={'pre'} variant="inherit" color="textPrimary">
              {JSON.stringify(error.parameter, null, 2) ?? 'empty'}
            </Typography>
            <Typography variant="inherit" component={'span'} fontWeight={'bold'}>
              Stack
            </Typography>
            <Typography pl={2} component={'pre'} variant="inherit" color="textPrimary">
              {error.stack}
            </Typography>
          </Stack>
        </Paper>
      </Box>
    );
  } else if (isValidationError(error)) {
    return (
      <Box display={'flex'} flexDirection={'column'} p={2}>
        <Paper>
          <Stack spacing={2} p={2}>
            <Typography variant="h2" color="textPrimary">
              {error.name}
            </Typography>

            <Typography component={'pre'} variant="inherit" color="textPrimary">
              {`${error.message}`}
            </Typography>
            <Typography component={'pre'} variant="inherit" color="textPrimary">
              {error.stack}
            </Typography>
          </Stack>
        </Paper>
      </Box>
    );
  } else if (error instanceof Error) {
    if (!children) {
      return <ErrorLayout scope={scope} />;
    }
    return children;
  } else {
    // eslint-disable-next-line no-console
    console.error('Unknown error', error);
    return (
      <Box display={'flex'} flexDirection={'column'} p={2}>
        <Paper>
          <Stack spacing={2} p={2}>
            <Typography variant="caption" color="textPrimary">
              Unknown Error
            </Typography>
          </Stack>
        </Paper>
      </Box>
    );
  }
}
