/* eslint-disable @typescript-eslint/naming-convention */
import { LoadingButton } from '@mui/lab';
import { Breadcrumbs, Chip, emphasize, Menu, MenuItem, Paper, Stack, styled } from '@mui/material';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Outlet, useMatch, useMatches, useNavigate } from 'react-router';

import { ensure } from '../../helpers/ensure';
import { useGetServices } from '../../hooks/useGetServices';
import { Icon } from '../../primitive/Icon/Icon';
import { LinkBehavior } from '../../primitive/LinkBehavior';

import { projectLoader } from './project.loader';
import { projectDetailPageLoader } from './ProjectDetailPage';
import { NO_CUSTOMER_ERROR } from './ProjectErrorPage';
import { projectListPageLoader } from './ProjectListPage';
import { projectSetupPageLoader } from './ProjectSetupPage';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(4),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      cursor: 'pointer',
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip; // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

export function ProjectRootLayout() {
  const matches = useMatches();
  const navigate = useNavigate();
  const client = useQueryClient();
  const { projectService } = useGetServices();

  const isProjectList = useMatch('/app/project/:customerId');

  const project = matches.find((match) => match.id === 'project');
  ensure(project, 'project loader match not found');

  const projectCustomer = matches.find((match) => match.id === 'project-customer');

  ensure(projectCustomer, 'project customer loader match not found', NO_CUSTOMER_ERROR);
  //
  // TODO
  //
  // on initial seed customer could not be assigned
  //
  // ensure(projectCustomer, 'project customer loader match not found');

  const projectDetail = matches.find((match) => match.id === 'project-detail');
  const projectSetup = matches.find((match) => match.id === 'project-setup');

  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorElement);

  const createProjectMutation = useMutation(projectService.create);
  const projectQuery = useSuspenseQuery(
    projectService.findManyProjectDTO({
      customerId: projectCustomer.params.customerId,
    }),
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement> | null) => {
    if (event) {
      setAnchorElement(event.currentTarget);
    }
  };

  const handleClose = (customerId: string) => {
    void sessionStorage.setItem('lastCustomerId', customerId);
    setAnchorElement(null);
  };

  //
  // because we don't use typegenerated routes we need to cast the data
  //
  const appLoaderData = project.data as Exclude<
    Awaited<ReturnType<typeof projectLoader>>,
    Response
  >;
  const projectLoaderData = projectCustomer.data as Awaited<
    ReturnType<typeof projectListPageLoader>
  >;

  const projectDetailLoaderData = projectDetail?.data as Awaited<
    ReturnType<typeof projectDetailPageLoader>
  >;

  const projectSetupLoaderData = projectSetup?.data as Awaited<
    ReturnType<typeof projectSetupPageLoader>
  >;

  // appLoaderData.customerList

  return (
    <>
      <Paper sx={{ px: 2, py: 1, mb: 2 }}>
        <Menu
          anchorEl={anchorElement}
          open={open}
          onClose={handleClose}
          aria-labelledby="customer-menu"
        >
          {appLoaderData.customerList.map((customer) => (
            <MenuItem
              key={customer.id}
              onClick={() => handleClose(customer.id)}
              href={`/app/project/${customer.id}`}
            >
              {customer.name}
            </MenuItem>
          ))}
        </Menu>
        <Stack
          flex={1}
          display="flex"
          alignItems={'center'}
          justifyContent="space-between"
          direction="row"
          spacing={1}
        >
          <Breadcrumbs aria-label="breadcrumbs">
            <StyledBreadcrumb
              component={LinkBehavior}
              label="Home"
              href={`/app/project/${projectCustomer.params.customerId}`}
              icon={<Icon name="Home" fontSize="small" />}
            />
            <StyledBreadcrumb
              label={projectLoaderData.customer.name}
              onClick={() => {
                void navigate(`/app/project/${projectCustomer.params.customerId}`);
              }}
              onDelete={handleClick}
              icon={
                <Icon
                  sx={{
                    ':hover': {
                      color: 'orange',
                    },
                  }}
                  name="Apartment"
                  fontSize="small"
                />
              }
              deleteIcon={<Icon name="ExpandMore" fontSize="small" />}
            />

            {projectDetailLoaderData && projectDetail && (
              <StyledBreadcrumb
                component={LinkBehavior}
                href={`/app/project/${projectCustomer.params.customerId}/${projectDetail.params.projectId}`}
                label={projectDetailLoaderData.project.name}
              />
            )}
            {projectSetupLoaderData && (
              <StyledBreadcrumb
                component={LinkBehavior}
                href={`/app/project/${projectCustomer.params.customerId}/${projectSetup?.params.projectId}`}
                label={projectSetupLoaderData.project?.name}
              />
            )}
            {projectSetup && (
              <StyledBreadcrumb
                component={LinkBehavior}
                href={`/app/project/${projectCustomer.params.customerId}/${projectSetup.params.projectId}/setup`}
                label={'Setup'}
                icon={<Icon name="SetupIcon" fontSize="small" />}
              />
            )}
          </Breadcrumbs>
          {isProjectList && (
            <Stack direction={'row'} spacing={1}>
              <LoadingButton
                loading={projectQuery.isFetching}
                disabled={projectQuery.isFetching}
                size="small"
                loadingPosition="start"
                onClick={() => {
                  //
                  // invalidate data force refetch all queries with the same queryKey
                  //
                  void client.invalidateQueries(
                    projectService.findManyProjectDTO({
                      customerId: projectCustomer.params.customerId,
                    }),
                  );
                }}
                startIcon={<Icon name="RefreshOutlined" />}
                variant="outlined"
              >
                Refresh
              </LoadingButton>
              <LoadingButton
                loading={createProjectMutation.isPending}
                disabled={createProjectMutation.isPending}
                size="small"
                loadingPosition="start"
                onClick={() => {
                  ensure(projectLoaderData.customer, 'customer not found');
                  //
                  // invalidate data force refetch all queries with the same queryKey
                  //
                  createProjectMutation.mutate(
                    {
                      name: 'New Project',
                      customerId: projectLoaderData.customer.id,
                      resellerId: projectLoaderData.customer.resellerId,
                    },
                    {
                      async onSuccess(data) {
                        await navigate(
                          `/app/project/${projectLoaderData.customer.id}/${data.id}/setup`,
                        );
                      },
                    },
                  );
                }}
                startIcon={<Icon name="Add" />}
                variant="outlined"
              >
                Create Project
              </LoadingButton>
            </Stack>
          )}
        </Stack>
      </Paper>
      <Outlet />
    </>
  );
}
