import { LoaderFunctionArgs, redirect } from 'react-router';

import { queryClient } from '../../config/client';
import { ensure } from '../../helpers/ensure';
import { CustomerService } from '../../service/Customer.service';

export async function projectLoader(request: LoaderFunctionArgs) {
  const customerService = new CustomerService();
  //
  // !!! BUG
  //
  // this does not return allways customers if user is assigned to project via ProjectTeam // createProjectAssignmentForEmail
  // this only add a projectAssignment to the user but not a customer
  //
  // const customerList = await queryClient.ensureQueryData(customerService.findManyAssigned());

  const response = await queryClient.ensureQueryData(customerService.findManyByUser());
  ensure(response, 'No customers found');
  const { customerList } = response;

  //
  // Could be accessed by useLoaderData
  // type ProjectListPageLoaderReturn = ReturnType<typeof projectListPageLoader>;
  // const { projectList } = useLoaderData<Awaited<ProjectListPageLoaderReturn>>();
  // !!! BUT if query gets invalidated, also the loader needs to be invalidated
  // const { revalidate } = useRevalidator();
  //
  // also with loader the feature of refetchOnWindowFocus would be lost
  //

  //
  // TODO
  //  - check if lastCustomerId is still valid
  //  - check if lastCustomerId is a string and not object

  const lastCustomer = await sessionStorage.getItem('lastCustomerId');
  const [customer] = customerList;
  if (request.params.customerId && lastCustomer !== request.params.customerId) {
    await sessionStorage.setItem('lastCustomerId', request.params.customerId);
  }

  if (lastCustomer && !request.params.customerId) {
    return redirect(`/app/project/${lastCustomer}`);
  }

  if (customer && !request.params.customerId) {
    return redirect(`/app/project/${customer.id}`);
  }

  return { customerList };
}
