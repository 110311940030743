import { KaeplaProject } from '@kaepla/types';
import { LoadingButton } from '@mui/lab';
import { Alert } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useMutation } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';

import { queryClient } from '../../../../config/client.js';
import { useGetServices } from '../../../../hooks/useGetServices.js';
import { Icon } from '../../../../primitive/Icon/Icon.js';
import { deleteProject } from '../../../../services/firestore/deleteProject.js';
import { projectsOverviewState } from '../../../../services/recoil/nonpersistent/projectsOverviewState.js';

interface Options {
  useService?: boolean;
  project: KaeplaProject;
  open: boolean;
  handleClose: () => void;
  handleMenuClose: () => void;
}

export const ProjectDeleteDialog = ({
  project,
  open,
  useService,
  handleClose,
  handleMenuClose,
}: Options) => {
  const [projectsOverview, setProjectsOverview] = useRecoilState(projectsOverviewState);
  const { projectService } = useGetServices();

  //
  // @cleanup
  //
  const deletingOnServer = async () => {
    const newProjectsOverview = [...projectsOverview].filter(
      (_project) => _project.id !== project.id,
    );
    setProjectsOverview(newProjectsOverview);
    await deleteProject({ project });
    // to see the new name we need to wait for the trigger on the server to fire
    await new Promise((resolve) => setTimeout(resolve, 6000));
  };

  const mutation = useMutation({
    mutationFn: () => deletingOnServer(),
    onSuccess() {
      handleClose();
      handleMenuClose();
    },
  });
  const mutationDelete = useMutation(projectService.delete);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle data-testid="dialog-title">Delete the project</DialogTitle>
      <DialogContent>
        <DialogContentText data-testid="delete-info">
          This will delete everything in your project and can't be undone. Along with the project
          associated data, project assignments, simulations commitments will be deleted.
        </DialogContentText>
        {(mutation.isPending || mutationDelete.isPending) && (
          <Alert
            data-testid="delete-progress-message"
            sx={{ mt: 1, mb: 1 }}
            variant="outlined"
            severity="info"
          >
            Deleting Project in progress...
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="button-cancel-delete"
          onClick={handleClose}
          disabled={mutation.isPending}
        >
          Cancel
        </Button>
        <LoadingButton
          color="error"
          variant="contained"
          data-testid="button-delete"
          startIcon={<Icon name="DeleteIcon" />}
          loadingPosition="start"
          loading={mutation.isPending || mutationDelete.isPending}
          disabled={
            mutation.isPending ||
            mutation.isSuccess ||
            mutationDelete.isPending ||
            mutationDelete.isSuccess
          }
          onClick={() => {
            if (useService) {
              mutationDelete.mutate(project.id, {
                async onSuccess() {
                  handleClose();
                  handleMenuClose();
                  await queryClient.invalidateQueries(
                    projectService.findManyProjectDTO({
                      customerId: project.customerId,
                    }),
                  );
                },
              });
            } else {
              void deletingOnServer();
            }
          }}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
