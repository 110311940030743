import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { IS_CI } from '../mode';

export function useAddCypressRouterHelper() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!IS_CI) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (globalThis.cyNavigate) return;
    // eslint-disable-next-line no-console
    console.info("Bind 'cyNavigate' to globalThis");
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    globalThis.cyNavigate = navigate;
  }, [navigate]);
}
