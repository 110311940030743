import { KaeplaProject } from '@kaepla/types';
import { Alert, Box, Link } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { getConnectorsForCustomer } from '../../../../services/firestore/getConnectorsForCustomer.js';
import { updateProject } from '../../../../services/firestore/updateProject.js';

import { ConnectorSelect } from './ConnectorSelect.js';

interface Options {
  project: KaeplaProject;
  open: boolean;
  handleClose: () => void;
  handleMenuClose: () => void;
}

export const ProjectConnectDialog = ({ project, open, handleClose, handleMenuClose }: Options) => {
  const [updatedAt] = useState(project.updatedAt);
  // const [connectors, setConnectors] = useState<KaeplaConnector[]>([]);
  const [selectedConnector, setSelectedConnector] = useState<string | undefined>(
    project.connectorId,
  );

  const query = useQuery({
    queryKey: ['connectors'],
    queryFn: () => getConnectorsForCustomer({ customerId: project.customerId }),
  });

  const connectors = query.data ?? [];

  // useEffect(() => {
  //   void (async () => {
  //     const _connectors = await getConnectorsForCustomer({ customerId: project.customerId });
  //     setConnectors(_connectors);
  //   })();
  // }, [project.customerId]);

  useEffect(() => {
    if (updatedAt !== project.updatedAt) {
      handleClose();
      handleMenuClose();
    }
  }, [handleClose, handleMenuClose, project.updatedAt, updatedAt]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle data-testid="dialog-title">Select a connector for the project</DialogTitle>
      {connectors.length === 0 && (
        <DialogContent>
          <Alert severity="warning">
            Please <Link href={`/Connectors/${project.customerId}`}>add connectors</Link> first.
          </Alert>
        </DialogContent>
      )}
      {connectors.length > 0 && (
        <DialogContent>
          <DialogContentText data-testid="connector-info">
            Once a connector is selected, it will take from a couple of minutes up to some hours for
            the project's data to be synched with the new source.
          </DialogContentText>
          <Box mt={3}>
            <ConnectorSelect
              connectors={connectors}
              value={selectedConnector}
              callback={setSelectedConnector}
            />
          </Box>
        </DialogContent>
      )}
      {connectors.length > 0 && (
        <DialogActions>
          <Button data-testid="button-cancel-connect" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            data-testid="button-connect"
            variant="contained"
            color="error"
            onClick={() => {
              if (!selectedConnector) return;
              void updateProject({
                project: {
                  ...project,
                  connectorId: selectedConnector,
                },
              });
            }}
          >
            Save
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
