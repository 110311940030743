import { KaeplaUser } from '@kaepla/types';
import { Avatar, AvatarGroup, Box, Skeleton, Stack } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { UserAvatar } from '../../Frontend/features/UserAvatar';
import { avatarGroupSlot, maxAvatarsDefault, scrollStyle } from '../../Frontend/Screens/defaults';
import { useGetServices } from '../../hooks/useGetServices';
import { ProjectDTO } from '../../service/Project.service';

interface ProjectTeam {
  project: ProjectDTO;
  disableInvites?: boolean;
  defaultPath?: string[];
}

export function ProjectTeam(properties: ProjectTeam) {
  const { userService } = useGetServices();
  const team = useQuery(userService.findTeamMembers(properties.project.id));
  const removeAssignmentFromUser = useMutation({
    mutationKey: ['UserService', 'leaveProject', properties.project.id],
    mutationFn: async (user: KaeplaUser) => {
      await userService.leaveProject(properties.project.id, user.uid);
    },
  });

  //
  // TODO invalidation of query
  // TODO invalidation of route
  //
  // https://tkdodo.eu/blog/react-query-meets-react-router
  //

  const [projectTeamOptions, setProjectTeamOptions] = useState({
    maxAvatarsDefault,
    showAll: false,
  });
  const { project } = properties;
  const teamLength = team.data?.length ?? 0;

  return (
    <Stack direction="row" justifyContent="space-between" data-testid="project-team" spacing={2}>
      <Box sx={projectTeamOptions.showAll && teamLength > 5 ? scrollStyle : { display: 'inherit' }}>
        <Box sx={{ pl: 1.5 }}>
          <AvatarGroup
            max={projectTeamOptions.maxAvatarsDefault}
            spacing="small"
            slotProps={avatarGroupSlot}
            onClick={() => {
              const showAll = !projectTeamOptions.showAll;
              setProjectTeamOptions({
                maxAvatarsDefault: projectTeamOptions.showAll ? maxAvatarsDefault : teamLength,
                showAll,
              });
            }}
          >
            {team.isLoading && (
              <Stack direction="row">
                <Avatar sx={{ width: 45, height: 45 }}>
                  <Skeleton variant="circular" width={45} height={45} animation="wave" />
                </Avatar>
                <Avatar sx={{ width: 45, height: 45 }}>
                  <Skeleton variant="circular" width={45} height={45} animation="wave" />
                </Avatar>
              </Stack>
            )}
            {team.data?.map((user) => (
              <UserAvatar
                key={user.uid}
                user={user}
                project={project}
                star={(project.ownedBy ?? project.createdBy) === user.uid}
                removeCallback={removeAssignmentFromUser.mutate}
                // resendInviteCallback={resendEmailInvite}
              />
            ))}
          </AvatarGroup>
        </Box>
      </Box>
    </Stack>
  );
}
