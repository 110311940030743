import { Box, Paper } from '@mui/material';
import { Outlet } from 'react-router';

import { useAddCypressRouterHelper } from './hooks/useAddCypressRouterHelper';
import { useNavigationLoading } from './hooks/useNavigationLoading';
import { KaeplaErrorProvider } from './KaeplaErrorProvider';
import { Icon } from './primitive/Icon/Icon';
import { KaeplaEventProvider } from './providers/KaeplaEvent.provider';
import { UserPreferencesProvider } from './UserPreferencesProvider';

export function RootLayout() {
  const loading = useNavigationLoading();
  useAddCypressRouterHelper();

  // useSuspenseQuery(repo.find(globalThis.location.hostname));
  //
  // Doesn't move listeners to MainAppContainer
  // Recoil does not revives changes in Layouts
  //
  return (
    <UserPreferencesProvider>
      <KaeplaErrorProvider>
        <KaeplaEventProvider>
          <Outlet />
          {loading && (
            <Paper elevation={10}>
              <Box p={2} m={2} position={'absolute'} bottom={0} left={0}>
                <Icon color="primary" name="loading" />
              </Box>
            </Paper>
          )}
        </KaeplaEventProvider>
      </KaeplaErrorProvider>
    </UserPreferencesProvider>
  );
}
