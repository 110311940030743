import { clientEventService } from '@kaepla/events';
import {
  FirestoreTimestamp,
  KaeplaPerspective,
  KaeplaProject,
  MatrixSimulationYears,
} from '@kaepla/types';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { useAuth } from '../../../../AuthReactProvider.js';
import { useUserPreferences } from '../../../../UserPreferencesProvider.js';
import { useAssignment } from '../../../../hooks/useAssignment.js';
import { GenericMenuItem } from '../../../../primitive/GenericMenuItem.js';
import { fireBaseDeleteField } from '../../../../services/firestore/_firestoreShorthands.js';
import { updateProject } from '../../../../services/firestore/updateProject.js';
import { dataGridSettings } from '../../../../services/recoil/nonpersistent/dataGridSets.js';
import { perspectiveState } from '../../../../services/recoil/nonpersistent/perspectiveState.js';
import { perspectivesState } from '../../../../services/recoil/nonpersistent/perspectivesState.js';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState.js';
import { simulationState } from '../../../../services/recoil/nonpersistent/simulationState.js';
import { snapShotState } from '../../../../services/recoil/nonpersistent/snapshotState.js';
import { timeSeriesState } from '../../../../services/recoil/nonpersistent/timeSeriesState.js';
import { currentScopePathState } from '../../../../services/recoil/persistent/currentScopePathState.js';
import { GenericConfirmDialog } from '../../../Layout/features/GenericDeleteConfirmDialog.js';

export function ProjectResetMenuItem({ project }: { project: KaeplaProject }) {
  const { setPreferences } = useUserPreferences();
  const { kaeplaUser } = useAuth();
  const [openProjectReset, setOpenProjectReset] = useState(false);
  const nav = useNavigate();
  const setProject = useSetRecoilState(projectState);
  const setCurrentScopePath = useSetRecoilState(currentScopePathState);
  const resetSimulation = useResetRecoilState(simulationState);
  const resetSnapshot = useResetRecoilState(snapShotState);
  const resetPerspective = useResetRecoilState(perspectiveState);
  const resetPerspectives = useResetRecoilState(perspectivesState);
  const resetGridSets = useResetRecoilState(dataGridSettings);
  const resetTimeSeries = useResetRecoilState(timeSeriesState);

  const { canManageProject } = useAssignment(project);

  if (!canManageProject) {
    return null;
  }

  function resetAndSetup() {
    setCurrentScopePath([]);
    /**
     * TODO: refactor all firestore updateDoc calls to handle undefined
     * an approach would be to map all possible undefined options properties from the type to fireBaseDeleteField()
     * AFTER casting the data to DocumentData from firestore which has any for all props
     */
    const newProject = { ...project, id: project.id } as KaeplaProject;
    delete newProject.initializedAt;
    delete newProject.defaultPerspective;
    delete newProject.simulationYears;
    delete newProject.totalDimensionsCount;
    delete newProject.totalRecordsCount;
    setProject(newProject);
    resetSimulation(); // in case we had  one selected - simulations are removed upon reset by server trigger
    resetSnapshot(); // this is to reset the snapshot state
    resetPerspective(); // this as well
    resetPerspectives(); // those as well
    resetGridSets(); // and those
    resetTimeSeries(); // and those
    setPreferences({ lastPerspectiveId: 'default' });
    const projectUpdate = { ...newProject, id: project.id } as KaeplaProject;
    // the deleted initializedAt indicates to clean up simulations etc. in the updateProject trigger
    projectUpdate.initializedAt = fireBaseDeleteField() as FirestoreTimestamp;
    projectUpdate.defaultPerspective = fireBaseDeleteField() as unknown as KaeplaPerspective;
    projectUpdate.simulationYears = fireBaseDeleteField() as unknown as MatrixSimulationYears;
    projectUpdate.totalDimensionsCount = fireBaseDeleteField() as unknown as number;
    projectUpdate.totalRecordsCount = fireBaseDeleteField() as unknown as number;
    void updateProject({ project: projectUpdate });

    // let's log this event
    void clientEventService.createEvent({
      assignmentScope: clientEventService.assignmentScope.PROJECT,
      eventGroup: clientEventService.eventGroup.PROJECTS,
      eventName: clientEventService.eventName.PROJECT_RESET_PROJECT,
      uid: kaeplaUser?.uid,
      projectId: project.id,
      customerId: project.customerId,
      resellerId: project.resellerId,
      scopePath: [],
    });

    void nav(`/Perspective/${project.id}`);
  }

  return (
    <>
      <GenericMenuItem
        text={project.initializedAt ? 'Reset' : 'Setup'}
        testId="project-reset"
        name="RestartAltOutlined"
        onClick={() => {
          if (project.initializedAt) {
            setOpenProjectReset(true);
            return;
          }
          resetAndSetup();
        }}
      />
      <GenericConfirmDialog
        openDelete={openProjectReset}
        title="Reset Project?"
        description="This action can not be reverted. All created perspectives, filters, simulations, project team member assignments will be lost."
        confirm="reset"
        handleCloseDelete={() => {
          setOpenProjectReset(false);
        }}
        processDelete={() => {
          resetAndSetup();
        }}
      />
    </>
  );
}
