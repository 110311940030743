import { Grid2, Paper, Stack, Typography } from '@mui/material';
import { useSuspenseQuery } from '@tanstack/react-query';
import { isEmpty, isNotEmpty } from 'rambda';
import { LoaderFunctionArgs, useLoaderData, useParams } from 'react-router';

import { useAuth } from '../../AuthReactProvider';
import { queryClient } from '../../config/client';
import { ensure } from '../../helpers/ensure';
import { useGetServices } from '../../hooks/useGetServices';
import { AUTH_INSTANCE } from '../../repository/base';
import { ProjectService } from '../../service/Project.service';
import { ProjectAssignmentService } from '../../service/ProjectAssignment.service';

export async function projectSetupPageLoader(parameters: LoaderFunctionArgs) {
  const { projectId, customerId } = parameters.params;
  const auth = AUTH_INSTANCE;
  ensure(auth.currentUser?.uid, 'User not found');
  ensure(projectId, 'projectId not found');
  ensure(customerId, 'customerId not found');

  const projectAssignmentService = new ProjectAssignmentService();
  const projectService = new ProjectService();

  const project = await queryClient.fetchQuery(projectService.find(projectId));

  const [assignment] = await queryClient.fetchQuery(
    projectAssignmentService.findMany({
      where: {
        uid: auth.currentUser.uid,
        customerId,
        projectId,
        complete: true,
      },
    }),
  );
  return { assignments: assignment, project };
}

export function ProjectSetupPage() {
  const { project } = useLoaderData<Awaited<ReturnType<typeof projectSetupPageLoader>>>();
  const auth = useAuth();
  const { projectAssignmentService } = useGetServices();
  const { customerId, projectId } = useParams<{ projectId: string; customerId: string }>();
  ensure(projectId, 'projectId not found');
  ensure(customerId, 'customerId not found');
  ensure(auth.user?.uid, 'User not found');
  const assignmentQuery = useSuspenseQuery(
    projectAssignmentService.findManyUntilComplete({
      where: {
        uid: auth.user?.uid,
        customerId,
        projectId,
        complete: true,
      },
    }),
  );

  return (
    <Paper>
      <Grid2 p={2} container flexDirection={'column'}>
        <Typography variant="h3">Project Setup</Typography>
        <Grid2 container>
          {isEmpty(assignmentQuery.data) && (
            <Typography variant="body2">
              Your new project might take a minute to show up here.
            </Typography>
          )}
          {isNotEmpty(assignmentQuery.data) && (
            <Stack spacing={2}>
              <Typography variant="body1" component={'p'}>
                Assignment
              </Typography>
              <Typography variant="body2" component={'pre'}>
                {JSON.stringify(assignmentQuery.data, null, 2)}
              </Typography>

              <Typography variant="body1" component={'p'}>
                Project
              </Typography>
              <Typography variant="body2" component={'pre'}>
                {JSON.stringify(project, null, 2)}
              </Typography>
            </Stack>
          )}
        </Grid2>
      </Grid2>
    </Paper>
  );
}
