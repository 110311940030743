import { ListItemIcon, ListItemText, MenuItem, MenuItemProps } from '@mui/material';
import { LinkProps } from 'react-router';

import { Icon, IconProperties } from './Icon/Icon';
import { LinkBehavior } from './LinkBehavior';

export interface BaseGenericMenuItemProperties extends Omit<IconProperties, 'onClick' | 'href'> {
  testId: string;
  disabled?: boolean;
  text: string;
}

export type GenericMenuItemProperties = BaseGenericMenuItemProperties & (AsButton | AsLink);

export interface AsButton {
  onClick: MenuItemProps['onClick'];
  href?: never;
}
export interface AsLink {
  href: LinkProps['to'];
  onClick?: never;
}

export function GenericMenuItem({
  disabled,
  href,
  testId,
  onClick,
  text,
  ...iconProperties
}: GenericMenuItemProperties) {
  if (href) {
    return (
      <MenuItem data-testid={testId} disabled={disabled} component={LinkBehavior} href={href}>
        <ListItemIcon>
          <Icon {...iconProperties} />
        </ListItemIcon>
        <ListItemText>{text}</ListItemText>
      </MenuItem>
    );
  }
  return (
    <MenuItem component="li" data-testid={testId} disabled={disabled} onClick={onClick}>
      <ListItemIcon>
        <Icon {...iconProperties} />
      </ListItemIcon>
      <ListItemText>{text}</ListItemText>
    </MenuItem>
  );
}
