import { KaeplaProject } from '@kaepla/types';
import { useRecoilValue } from 'recoil';

import { useAuth } from '../AuthReactProvider.js';
import { kaeplaAssignmentState } from '../services/recoil/persistent/kaeplaAssignmentState.js';

export function useAssignment(project: KaeplaProject) {
  const { kaeplaUser } = useAuth();
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const isOwner = project.ownedBy === kaeplaUser?.uid;
  const isCreator = project.createdBy === kaeplaUser?.uid;
  const isDevelopmentTeamMember =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    kaeplaAssignment?.devTeamMember && kaeplaAssignment.id === kaeplaUser?.uid;

  const canManageProject = isOwner || isCreator || isDevelopmentTeamMember;

  return {
    hasAssignment: kaeplaAssignment !== undefined,
    isOwner,
    isCreator,
    isDevelopmentTeamMember,
    canManageProject,
  };
}
