import { KaeplaProject } from '@kaepla/types';
import { Divider, Menu, MenuList, MenuProps } from '@mui/material';
import { useState } from 'react';

import { useAssignment } from '../../../../hooks/useAssignment';
import { GenericMenuItem } from '../../../../primitive/GenericMenuItem';

import { ProjectConfigureDialog } from './ProjectConfigureDialog';
import { ProjectConnectDialog } from './ProjectConnectDialog';
import { ProjectDeleteDialog } from './ProjectDeleteDialog';
import { ProjectRenameDialog } from './ProjectRenameDialog';
import { ProjectResetMenuItem } from './ProjectResetMenuItem';

export function CardMenu({
  project,
  useService,
  onHandleClose,
  ...properties
}: MenuProps & { project: KaeplaProject; useService?: boolean; onHandleClose?: () => void }) {
  const [command, setCommand] = useState<
    'rename' | 'none' | 'delete' | 'connect' | 'configure' | 'reset'
  >('none');
  const { canManageProject, isDevelopmentTeamMember } = useAssignment(project);
  return (
    <Menu
      anchorEl={properties.anchorEl}
      open={properties.open}
      onClose={properties.onClose}
      data-testid="project-menu"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <MenuList disablePadding={true}>
        <GenericMenuItem
          text="Rename"
          testId="project-rename"
          name="RenameIcon"
          onClick={() => {
            setCommand('rename');
          }}
        />
        <Divider />
        <GenericMenuItem
          disabled={!canManageProject}
          text="Connect"
          testId="project-connect"
          name="ConnectIcon"
          onClick={() => {
            setCommand('connect');
          }}
        />
        <ProjectResetMenuItem project={project} />
        {isDevelopmentTeamMember && (
          <GenericMenuItem
            disabled={!canManageProject}
            text="Clone Project"
            testId="project-clone-admin"
            name="ContentCopyOutlined"
            href={`/CloneProject/${project.id}`}
          />
        )}
        <GenericMenuItem
          disabled={!isDevelopmentTeamMember}
          text="Configure"
          testId="project-configure"
          name="ConfigureIcon"
          onClick={() => {
            setCommand('configure');
          }}
        />
        <Divider />
        <GenericMenuItem
          disabled={!canManageProject}
          text="Delete"
          testId="project-delete"
          name="DeleteIcon"
          onClick={() => {
            setCommand('delete');
          }}
        />
      </MenuList>
      {command === 'rename' && (
        <ProjectRenameDialog
          open={true}
          useService={useService}
          handleClose={() => setCommand('none')}
          handleMenuClose={() => {
            setCommand('none');
            onHandleClose && onHandleClose();
          }}
          project={project}
        />
      )}
      {command === 'delete' && (
        <ProjectDeleteDialog
          open={true}
          useService={useService}
          handleClose={() => setCommand('none')}
          handleMenuClose={() => {
            setCommand('none');
            onHandleClose && onHandleClose();
          }}
          project={project}
        />
      )}
      {command === 'connect' && (
        <ProjectConnectDialog
          open={true}
          handleClose={() => setCommand('none')}
          handleMenuClose={() => {
            setCommand('none');
            onHandleClose && onHandleClose();
          }}
          project={project}
        />
      )}
      {command === 'configure' && (
        <ProjectConfigureDialog
          open={true}
          handleClose={() => setCommand('none')}
          handleMenuClose={() => {
            setCommand('none');
            onHandleClose && onHandleClose();
          }}
          project={project}
        />
      )}
    </Menu>
  );
}
