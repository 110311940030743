import { Grid2, Paper, Typography } from '@mui/material';
import { useSuspenseQuery } from '@tanstack/react-query';
import { LoaderFunctionArgs, useParams } from 'react-router';

import { queryClient } from '../../config/client';
import { ensure } from '../../helpers/ensure';
import { useGetServices } from '../../hooks/useGetServices';
import { ProjectService } from '../../service/Project.service';

export async function projectDetailPageLoader(parameters: LoaderFunctionArgs) {
  ensure(parameters.params.projectId, 'projectId not found');
  const projectService = new ProjectService();
  const project = await queryClient.ensureQueryData(
    projectService.findProjectDTO(parameters.params.projectId),
  );
  return {
    project,
  };
}

export function ProjectDetailPage() {
  const { projectId } = useParams();
  const { projectService } = useGetServices();
  ensure(projectId, 'projectId not found');
  const query = useSuspenseQuery(projectService.findProjectDTO(projectId));
  return (
    <Paper>
      <Grid2 p={2} container flexDirection={'column'}>
        <Typography variant="h3">Project {projectId}</Typography>
        <Typography variant="body2" component={'pre'}>
          <pre>{JSON.stringify(query.data, null, 2)}</pre>
        </Typography>
      </Grid2>
    </Paper>
  );
}
