import { KaeplaBranding } from '@kaepla/types';
import { Typography, TypographyProps } from '@mui/material';
import Link from '@mui/material/Link';

interface CopyrightProperties extends TypographyProps {
  branding: KaeplaBranding | null;
}

export function Copyright({ branding, ...properties }: CopyrightProperties) {
  return (
    <Typography
      data-testid="footer-copyright"
      variant="body2"
      color="text.secondary"
      align="center"
      {...properties}
      sx={{ mb: 2, ml: -3, p: 2, left: '50%' }}
    >
      <Link
        sx={{ cursor: 'pointer' }}
        color="inherit"
        underline="hover"
        data-testid="sysinfo-link"
        href="/Disclaimers"
      >
        {'© '} {new Date().getFullYear()}
      </Link>{' '}
      - Brought to you by{' '}
      {branding?.providerIconBase64 && (
        <img
          width="16"
          style={{ verticalAlign: 'text-bottom' }}
          src={branding.providerIconBase64}
          alt={branding?.appName || 'Logo'}
        />
      )}{' '}
      {branding?.providerLinkUrl ? (
        <Link color="inherit" underline="hover" href={branding.providerLinkUrl}>
          {branding.appProvider}
        </Link>
      ) : (
        branding?.appProvider
      )}
      .
    </Typography>
  );
}
