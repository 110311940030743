/* eslint-disable @typescript-eslint/no-misused-promises */

import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Grid2 as Grid,
  IconButton,
  Link,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useSuspenseQuery } from '@tanstack/react-query';
import { isEmpty } from 'rambda';
import { useState } from 'react';
import { LoaderFunctionArgs, Outlet, useParams } from 'react-router';

import { queryClient } from '../../config/client';
import { simplifyScopePath } from '../../Frontend/helpers/simplifyScopePath';
import { avatarAppearDuration } from '../../Frontend/Screens/defaults';
import { CardMenu } from '../../Frontend/Screens/Projects/features/CardMenu';
import { ensure } from '../../helpers/ensure';
import { useAssignment } from '../../hooks/useAssignment';
import { useGetServices } from '../../hooks/useGetServices';
import { useNavigationLoading } from '../../hooks/useNavigationLoading';
import { Icon } from '../../primitive/Icon/Icon';
import { Image } from '../../primitive/Image/Image';
import { CustomerService } from '../../service/Customer.service';
import { ProjectDTO, ProjectService } from '../../service/Project.service';

import { ProjectTeam } from './ProjectTeam';

//
// Make sure all data are loaded before rendering the page
//
export async function projectListPageLoader(parameters: LoaderFunctionArgs) {
  const projectService = new ProjectService();
  const customerService = new CustomerService();

  ensure(parameters?.params?.customerId, 'customerId is required');

  const customer = await queryClient.ensureQueryData(
    customerService.find(parameters?.params?.customerId),
  );

  ensure(customer, 'customer not found');

  const projectList = await queryClient.ensureQueryData(
    projectService.findManyProjectDTO({
      customerId: customer.id,
    }),
  );

  //
  // Could be accessed by useLoaderData
  // type ProjectListPageLoaderReturn = ReturnType<typeof projectListPageLoader>;
  // const { projectList } = useLoaderData<Awaited<ProjectListPageLoaderReturn>>();
  // !!! BUT if query gets invalidated, also the loader needs to be invalidated
  // const { revalidate } = useRevalidator();
  //
  // also with loader the feature of refetchOnWindowFocus would be lost
  //
  return { projectList, customer };
}

export function ProjectListLayout() {
  const loading = useNavigationLoading();
  if (loading) {
    return (
      <Box
        data-testid={'router-loading'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        height={'100vh'}
        width={'full'}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid container flexDirection={'column'} spacing={2}>
      <Outlet />
    </Grid>
  );
}

export function ProjectListPage() {
  const { customerId } = useParams<{ customerId: string }>();
  ensure(customerId, 'customerId is required');
  const { projectService, customerService } = useGetServices();
  const projectQuery = useSuspenseQuery(
    projectService.findManyProjectDTO({
      customerId,
    }),
  );

  const customer = useSuspenseQuery(customerService.find(customerId));
  ensure(customer.data, 'customer not found');

  const projectList = projectQuery.data;

  if (isEmpty(projectList)) {
    return (
      <Grid size={12}>
        <Paper
          data-testid="no-project-need-invite"
          sx={{ p: 6 }}
        >{`No projects yet. You need to be invited to one! ;-)`}</Paper>
      </Grid>
    );
  }

  //
  // TODO
  //
  //  - projectInCreation ??
  //
  // BUG
  //
  //  - permission error when user create fast two projects after each other
  //
  //  - user assignment to project has 3 different ways .... why ? // increase in complexity // data model is not consistent
  //

  // ADMIN
  //
  //  - customer list for admins doesn't return all customers

  return (
    <Grid container flexDirection={'column'} spacing={1}>
      <Grid container spacing={2}>
        {projectList?.map((project) => (
          <ProjectCard key={project.id} customerId={customerId} project={project} />
        ))}
      </Grid>
    </Grid>
  );
}

interface ProjectCardProperties {
  project: ProjectDTO;
  customerId: string;
}
function ProjectCard({ project, customerId }: ProjectCardProperties) {
  const theme = useTheme();
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const { canManageProject, hasAssignment } = useAssignment(project);
  //
  // @rrust
  // IS this scoping feature
  //
  const defaultPath: string[] = [];
  return (
    <>
      <CardMenu
        useService={true}
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={() => setAnchorElement(null)}
        onHandleClose={() => setAnchorElement(null)}
        project={project}
      />
      <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
        <Card
          className="project-card"
          data-testid="project-card"
          data-project-id={project.id}
          sx={{
            opacity: 0.8,
            cursor: 'pointer',
            position: 'relative',
            height: '100%',
            ':hover': {
              opacity: 1,
              //
              // does the same as elevation: 8
              //
              boxShadow: 8,
            },
          }}
        >
          <CardHeader
            data-testid={`project-card-header-${project.id}`}
            title={
              <CardActionArea>
                <Link href={`/app/project/${customerId}/${project.id}`}>
                  <Stack p={1} direction="row" spacing={1}>
                    <Avatar
                      data-testid="project-avatar"
                      sx={{
                        bgcolor: 'transparent',
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor:
                          theme.palette.mode === 'light'
                            ? theme.palette.grey[300]
                            : theme.palette.grey[800],
                      }}
                      variant="square"
                    >
                      {project.customer?.iconBase64 && (
                        <Image
                          data-testid="project-avatar-image"
                          width="70%"
                          height="70%"
                          alt={project.customer?.name}
                          src={project.customer?.iconBase64}
                          duration={avatarAppearDuration}
                        />
                      )}
                    </Avatar>

                    <Stack direction={'column'}>
                      <Typography variant="subtitle1" data-testid="project-name">
                        {project.name}
                      </Typography>
                      <Tooltip title={project.id}>
                        <Typography variant="subtitle2" data-testid="project-subheader">
                          {project.getSubHeader}
                        </Typography>
                      </Tooltip>
                    </Stack>
                  </Stack>
                </Link>
              </CardActionArea>
            }
            titleTypographyProps={{
              noWrap: true,
            }}
            action={
              <IconButton
                edge="end"
                size="small"
                disabled={!canManageProject && !hasAssignment}
                data-testid="project-menu-toggle"
                onClick={(event) => {
                  setAnchorElement(event.currentTarget);
                }}
              >
                <Icon name="MoreVert" />
              </IconButton>
            }
          />
          <CardContent
            sx={{
              minHeight: 100,
              py: 0,
            }}
          >
            <Grid container spacing={1}>
              <Grid size={12}>
                <Grid
                  container
                  flexWrap="wrap"
                  alignItems={'center'}
                  spacing={0.5}
                  // sx={{
                  //   flexWrap: 'wrap',
                  //   alignItems: 'center',
                  // }}
                >
                  <Chip
                    data-testid="project-records"
                    label={project.formattedTotalRecordsCount}
                    size="small"
                    variant="outlined"
                  />
                  <Chip
                    //
                    // why only as admin ?
                    //
                    data-testid="project-dimensions"
                    label={project.formattedDimensions}
                    size="small"
                    variant="outlined"
                  />
                  <Chip
                    data-testid="project-assignmentsCount"
                    label={`${project.formattedAssignmentsCount}`}
                    size="small"
                    variant="outlined"
                  />
                  {project.connector && !project.connector.error?.stopConnection && (
                    <Chip
                      color="success"
                      avatar={<Icon name="ConnectIcon" fontSize="small" />}
                      data-testid="project-connected"
                      label="connected"
                      size="small"
                      variant="outlined"
                    />
                  )}
                  {project.connector?.error?.stopConnection && (
                    <Chip
                      color="error"
                      avatar={<Icon name="ConnectIcon" fontSize="small" />}
                      data-testid="project-connection-broken"
                      label={
                        project.connector.error.code === 'passwordExpired'
                          ? 'password expired'
                          : 'error'
                      }
                      size="small"
                      variant="outlined"
                    />
                  )}
                </Grid>
              </Grid>
              <Grid size={12}>
                <Typography variant="caption">
                  {defaultPath.length > 0
                    ? simplifyScopePath(defaultPath).join(' ➔ ')
                    : 'All Scopes'}
                </Typography>
                <Divider />
              </Grid>
              <Grid size={12}>
                <ProjectTeam project={project} disableInvites={true} defaultPath={defaultPath} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
