import { KaeplaProject } from '@kaepla/types';
import { getApp } from 'firebase/app';
import { deleteDoc, doc, getFirestore } from 'firebase/firestore';

import { ensure } from '../../helpers/ensure';

interface Options {
  project: KaeplaProject;
}

export const deleteProject = async ({ project }: Options) => {
  ensure(project.ownedBy, 'ownedBy is required');
  ensure(project.createdBy, 'createdBy is required');

  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${project.id}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);
  return deleteDoc(firestoreDocumentReference);
};
