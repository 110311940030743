import {
  AddOutlined,
  Apartment,
  CheckCircle,
  ContentCopyOutlined,
  DeleteOutline,
  EditOutlined,
  ErrorOutlineRounded,
  ExpandMore,
  Home,
  LinkOutlined,
  List,
  MoreVert,
  OpenInNewOutlined,
  PendingActionsOutlined,
  Person2Outlined,
  PersonAddOutlined,
  RefreshOutlined,
  RestartAltOutlined,
  ScheduleOutlined,
  SettingsOutlined,
  SyncOutlined,
} from '@mui/icons-material';
import { Box, CircularProgress, SvgIconProps } from '@mui/material';
import { PropsWithChildren } from 'react';

import { UnhandledCaseError } from '../../Frontend/helpers/UnhandledCaseError';
import { IS_DEV } from '../../mode';

export interface IconProperties extends SvgIconProps {
  loading?: boolean;
  name:
    | 'Add'
    | 'Apartment'
    | 'MoreVert'
    | 'AddOutline'
    | 'AdminIcon'
    | 'AutomatedIcon'
    | 'ContentCopyOutlined'
    | 'ConfigureIcon'
    | 'ConnectIcon'
    | 'DeleteIcon'
    | 'DeleteOutline'
    | 'FailedIcon'
    | 'Home'
    | 'List'
    | 'ImportIcon'
    | 'ExpandMore'
    | 'JoinIcon'
    | 'loading'
    | 'OpenProjectIcon'
    | 'PendingActionsOutlined'
    | 'RefreshOutlined'
    | 'Remove'
    | 'RemoveOutline'
    | 'RenameIcon'
    | 'RestartAltOutlined'
    | 'SetupAndResetIcon'
    | 'SetupIcon'
    | 'SucceededIcon';

  center?: boolean;
}

function Center(properties: PropsWithChildren<unknown>) {
  return (
    <Box height={'100%'} display={'flex'} alignItems={'center'}>
      {properties.children}
    </Box>
  );
}
function BaseIcon({ name, ...properties }: Omit<IconProperties, 'center'>) {
  switch (name) {
    case 'Apartment': {
      return <Apartment {...properties} />;
    }
    case 'ConnectIcon': {
      return <LinkOutlined {...properties} />;
    }
    case 'DeleteIcon': {
      return <DeleteOutline {...properties} />;
    }
    case 'JoinIcon': {
      return <PersonAddOutlined {...properties} />;
    }
    case 'Home': {
      return <Home {...properties} />;
    }
    case 'List': {
      return <List {...properties} />;
    }
    case 'MoreVert': {
      return <MoreVert {...properties} />;
    }
    case 'ExpandMore': {
      return <ExpandMore {...properties} />;
    }
    case 'RefreshOutlined': {
      return <RefreshOutlined {...properties} />;
    }

    case 'ContentCopyOutlined': {
      return <ContentCopyOutlined {...properties} />;
    }

    case 'SetupAndResetIcon':
    case 'RestartAltOutlined': {
      return <RestartAltOutlined {...properties} />;
    }
    case 'ImportIcon': {
      return <SyncOutlined {...properties} />;
    }
    case 'ConfigureIcon':
    case 'SetupIcon': {
      return <SettingsOutlined {...properties} />;
    }
    case 'RenameIcon': {
      return <EditOutlined {...properties} />;
    }
    case 'SucceededIcon': {
      return <CheckCircle {...properties} />;
    }
    case 'FailedIcon': {
      return <ErrorOutlineRounded {...properties} />;
    }

    case 'OpenProjectIcon': {
      return <OpenInNewOutlined {...properties} />;
    }
    case 'AdminIcon': {
      return <Person2Outlined {...properties} />;
    }
    case 'AutomatedIcon': {
      return <ScheduleOutlined {...properties} />;
    }
    case 'PendingActionsOutlined': {
      return <PendingActionsOutlined {...properties} />;
    }
    case 'AddOutline':
    case 'Add': {
      return <AddOutlined {...properties} />;
    }
    case 'Remove':
    case 'RemoveOutline':
    case 'DeleteOutline': {
      return <DeleteOutline {...properties} />;
    }
    case 'loading': {
      return (
        <CircularProgress
          // todo add color to the loading icon
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          color={properties.color ?? 'inherit'}
          {...properties}
        />
      );
    }
    default: {
      if (IS_DEV) {
        throw new UnhandledCaseError(name);
      }
      return null;
    }
  }
}

export function Icon({ center, loading = false, name, ...properties }: IconProperties) {
  if (loading) {
    const size = properties.height ?? properties.width ?? 24;
    return (
      <CircularProgress
        data-loading
        // todo add color to the loading icon
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        color={properties.color ?? 'inherit'}
        size={size}
      />
    );
  }
  if (center) {
    return (
      <Center>
        <BaseIcon name={name} {...properties} />
      </Center>
    );
  }

  return <BaseIcon name={name} {...properties} />;
}
