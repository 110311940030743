import { Grid2, Paper } from '@mui/material';
import { useRouteError } from 'react-router';

import { ErrorLayout } from '../../primitive/ErrorBoundary';

export const NO_CUSTOMER_ERROR = 'NO_CUSTOMER_ERROR';
export const NO_PROJECT_ERROR = 'NO_PROJECT_ERROR';

export function ProjectErrorNoCustomer() {
  return (
    <Grid2 size={12}>
      <Paper data-testid="no-customers-info" sx={{ p: 6 }}>
        Your User has no customers yet.
      </Paper>
    </Grid2>
  );
}

export function ProjectErrorNoProjects() {
  return (
    <Grid2 size={12}>
      <Paper data-testid="no-projects-info" sx={{ p: 6 }}>
        Your User has no projects yet.
      </Paper>
    </Grid2>
  );
}

export function ProjectErrorPage() {
  const error = useRouteError() as Error;

  if (error.cause === NO_CUSTOMER_ERROR) {
    return <ProjectErrorNoCustomer />;
  }

  if (error.cause === NO_PROJECT_ERROR) {
    return <ProjectErrorNoProjects />;
  }

  return <ErrorLayout />;
}
