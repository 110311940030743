/* eslint-disable no-console */
import ReactDOM from 'react-dom/client';

import { Root } from './App.js';
try {
  const root = ReactDOM.createRoot(document.querySelector('#root')!);
  root.render(<Root />);
} catch (error) {
  console.error(error);
  throw error;
}
