import { Box, CircularProgress } from '@mui/material';

import { useAddCypressRouterHelper } from '../hooks/useAddCypressRouterHelper';

export function PageLoading() {
  useAddCypressRouterHelper();

  return (
    <Box
      data-testid={'router-loading'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      height={'100vh'}
      width={'full'}
    >
      <CircularProgress />
    </Box>
  );
}
