import { forwardRef } from 'react';
import { Link, LinkProps } from 'react-router';

export const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<LinkProps, 'to'> & { href: LinkProps['to'] }
>((properties, reference) => {
  const { href, ...other } = properties;
  // Map href (Material UI) -> to (react-router)
  return <Link ref={reference} to={href} {...other} />;
});
